// Spin.js

import React from 'react';
import './Spin.css';

function Spin({ num, artist, track, album, label, year, cover }) {
    return (
        <div className='spin'>
            <div className='numAndSpin'>
                <span className='numText'>{num}.</span>
                <img src={cover} alt="spinImg" width="60px" height="60px" className='spinImg'></img>
            </div>
            <div className='spinText'>
                <p><span className='artistText'>{artist}</span>: '{track}'</p>
                <p><span className='albumText'>{album}</span> / {label} / {year}</p>
            </div>
        </div>
    );
};

export default Spin;