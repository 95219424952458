// LastEpisode.js

import React from 'react';
import './LastEpisode.css';
import download from '../../assets/download.png';
import external from '../../assets/external.png';
import Spin from '../Spin/Spin';

const NowPlaying = React.lazy(() => import('../NowPlaying/NowPlaying'));

function MostRecent() {

    /* const audioSrc = "http://35.7.34.15:5150/(0) Last Episode/LastEpisode.mp3" */
    const audioSrc = "https://djdoubletwo.duckdns.org:5150/(0) Last Episode/LastEpisode.mp3"
    const driveLink = "https://drive.google.com/uc?export=download&id=1lJbJjO8BqiKgUlgq01NcEGj2zeNqpl6t"
    const epName = "12/13/24 They Reminisce Over You"
    const spinLink = "https://spinitron.com/WCBN/pl/19913876/Live-from-the-Dungeon"
    const lastDescription = "This week we hear what MCs have to say about times and people of the past. Enjoy."
    const spins = [
        { num: "1", artist: "Pete Rock", track: "They Reminisce over You (T.R.O.Y.) [Vibes Mix]", album: "Mecca And The Soul Brother (Deluxe Edition)", label: "Rhino/Elektra", year: "1992", cover: require("../../assets/Spins/spin1.jpg") },
        { num: "2", artist: "O.C.", track: "Born 2 Live", album: "Word...Life", label: "Wild Pitch", year: "1994", cover: require("../../assets/Spins/spin2.jpg") },
        { num: "3", artist: "Dred Scott", track: "Back in the Day (Nappy Disc)", album: "Breakin' Combs", label: "Tuff Break Records", year: "1994", cover: require("../../assets/Spins/spin3.jpg") },
        { num: "4", artist: "Common", track: "Reminding Me (Of Sef) (feat. Chantay Savage)", album: "One Day It'll All Make Sense", label: "Relativity Records", year: "1997", cover: require("../../assets/Spins/spin4.jpg") },
        { num: "5", artist: "Big Punisher", track: "You Came Up (feat. Noreaga)", album: "Capital Punishment", label: "LOUD Records", year: "1998", cover: require("../../assets/Spins/spin5.jpg") },
        { num: "6", artist: "Nas", track: "Memory Lane (Sittin' In da Park)", album: "Illmatic", label: "Legacy Recordings", year: "1994", cover: require("../../assets/Spins/spin6.jpg") },
        { num: "7", artist: "Lost Boyz", track: "Renee", album: "Legal Drug Money", label: "UNI/MOTOWN", year: "1996", cover: require("../../assets/Spins/spin7.jpg") },
        { num: "8", artist: "Capital Tax", track: "I Can't Believe It", album: "The Swoll Package", label: "Geffen", year: "1993", cover: require("../../assets/Spins/spin8.jpg") },
        { num: "9", artist: "Ahmad", track: "Back in the Day", album: "Ahmad", label: "Giant", year: "1994", cover: require("../../assets/Spins/spin9.jpg") },
        { num: "10", artist: "Mos Def", track: "Love", album: "Black On Both Sides", label: "Rawkus Entertainment", year: "1999", cover: require("../../assets/Spins/spin10.jpg") },
        { num: "11", artist: "dj honda", track: "Trouble in the Water (feat. De La Soul)", album: "h II", label: "dj honda RECORDINGS", year: "1998", cover: require("../../assets/Spins/spin11.jpg") },
        { num: "12", artist: "The Notorious B.I.G.", track: "Sky's the Limit (feat. 112)", album: "Life After Death (2014 Remaster)", label: "Rhino Atlantic", year: "1997", cover: require("../../assets/Spins/spin12.jpg") },
    ];

    return (
        <div className='lastEpisode'>
            <h1>Last Episode<hr className='headerHR'/></h1>
            <div className='recentContent'>
                <h2 className='lastLink'><a href={driveLink}>{epName}: <img src={ download } className='downloadIcon' alt='Download Icon' width='25px'/></a></h2>
                <div className='lastDescription'>{lastDescription}</div>
                <NowPlaying name={epName} linkSrc={audioSrc} />
                <hr/>
                <div className='tracks'>
                    <h2 className='lastLink'><a href={spinLink} target='_blank' rel='noreferrer'>Playlist:  <img src={ external } className='externalIcon' alt='External Icon' width='20px'/></a></h2>
                    {spins.map((trackInfo, index) => (
                        <Spin key={index} {...trackInfo} />
                    ))}
                </div>
            </div>
            <hr/>
        </div>
    );
};

export default MostRecent;